import React from 'react';

import { getRewrittenUrl } from '@fie/analytics-library';
import type { Event } from '@fie/analytics-library/src/types/Analytics';

import type { Deal } from '../../../../types/Deal';
import type { SeasonalEventMerchant } from '../../../../types/SeasonalEvent';
import { useHawkWidgetContext } from '../../HawkWidget/HawkWidgetProviderContext';
import { TargetEnum } from '../../TrackedLink/TrackedLink';

import styles from './styles/seasonal-event-merchant.css';

interface SeasonalEventMerchantComponentProps {
  merchant: SeasonalEventMerchant;
  className?: string;
}

const SeasonalEventMerchantComponent: React.FC<SeasonalEventMerchantComponentProps> = ({
  merchant,
  className,
}): JSX.Element => {
  const linkRef = React.useRef<HTMLAnchorElement | null>(null);
  const {
    site,
    area,
    articleName,
    articleUrl,
    sendAnalytics,
    genericSharedComponents: { LazyImage, TrackedLink },
  } = useHawkWidgetContext();

  const getUrl = (customTrackingId: string): { url: string } => {
    return {
      url: getRewrittenUrl(
        merchant.merchant_url,
        site && site.analyticsName,
        area,
        customTrackingId,
        articleUrl,
        articleName,
      ),
    };
  };

  const trackLinkClick = (event): void => {
    if (sendAnalytics) {
      const { url } = getUrl(merchant.customTrackingId || '');
      sendAnalytics(
        {
          label: merchant.merchant_name,
          clickType: 'Seasonal event retailer',
          prefix: 'Click from',
        } as Event, // TODO: remove type chagne after fie-analytics Event type is checked
        // We do create deal structure to provide data to analytics
        {
          offer: {
            custom_tracking_id: merchant.customTrackingId,
            link: url,
          },
          merchant: {
            id: merchant.merchant_id,
            name: merchant.merchant_name,
            url: merchant.merchant_url,
          },
        } as unknown as Deal, // TODO: this should be checked why are we creating this small Deal compared to desired type
        event,
      );
    }
  };

  const containerStyle = className && styles[className] ? className : 'container';

  return (
    <div className={styles[containerStyle]}>
      <>
        <TrackedLink
          {...{
            trackLinkClick,
            customTarget: TargetEnum.BLANK,
            attributes: {
              ref: linkRef,
              className: styles['link'],
              href: getUrl(merchant.customTrackingId || '').url,
            },
          }}
        >
          <div className={styles['image-container']}>
            <LazyImage
              {...{
                src: merchant.logo,
                alt: merchant.merchant_name,
                width: 100,
                height: 50,
              }}
            />
          </div>
        </TrackedLink>
      </>
    </div>
  );
};

export default SeasonalEventMerchantComponent;
